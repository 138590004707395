<template>
  <b-card :title="title">
    <vue-apex-charts
      type="radar"
      height="300"
      :options="apexChatData.radarChart.chartOptions"
      :series="apexChatData.radarChart.series"
      v-if="this.data.length"
    />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "./apexChartData";

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      apexChatData,
    };
  },
  watch: {
    categories() {
      this.apexChatData.radarChart.chartOptions.xaxis.categories =
        this.categories;
    },
    data() {
      this.apexChatData.radarChart.series[0].data = this.data;
    },
  },
};
</script>
