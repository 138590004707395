var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-0"},[_c('b-card-title',[_vm._v(_vm._s(_vm.data.title))])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"d-flex flex-column flex-wrap text-center",attrs:{"sm":"2"}},[_c('h1',{staticClass:"font-large-2 font-weight-bolder mt-2 mb-0"},[_vm._v(" "+_vm._s(_vm.data.totalRequests)+" ")]),_c('small',[_vm._v("Requests")])]),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"sm":"10"}},[_c('vue-apex-charts',{attrs:{"type":"radialBar","height":"270","options":_vm.supportTrackerRadialBar.chartOptions,"series":_vm.data.supportTrackerRadialBar.series}})],1)],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"text-center"},[_c('b-card-text',{staticClass:"mb-50"},[_vm._v(" New Requests ")]),_c('span',{staticClass:"font-large-1 font-weight-bold"},[_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'list-stock-request',
              query: { state: 'requested' },
            }}},[_vm._v(" "+_vm._s(_vm.data.requested)+" ")])],1)],1),_c('div',{staticClass:"text-center"},[_c('b-card-text',{staticClass:"mb-50"},[_vm._v(" Approved Requests ")]),_c('span',{staticClass:"font-large-1 font-weight-bold"},[_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'list-stock-request',
              query: { state: 'approved' },
            }}},[_vm._v(" "+_vm._s(_vm.data.approved)+" ")])],1)],1),_c('div',{staticClass:"text-center"},[_c('b-card-text',{staticClass:"mb-50"},[_vm._v(" Prepared Requests ")]),_c('span',{staticClass:"font-large-1 font-weight-bold"},[_c('b-link',{staticClass:"link-underline",attrs:{"to":{
              name: 'list-stock-request',
              query: { state: 'prepared' },
            }}},[_vm._v(" "+_vm._s(_vm.data.prepared)+" ")])],1)],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }