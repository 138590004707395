<template>
  <section id="dashboard-analytics">
    <load-profile></load-profile>
    
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <medal :data="data.congratulations" />
      </b-col>
      <b-col xl="8" md="6">
        <statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <request-tracker :data="data.supportTracker" />
      </b-col>
      <b-col lg="6">
        <radar-chart key="section" title="Request by Section Used" :categories="data.sections" :data="data.sectionsData" />
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col cols="12">
        <bar-chart title="Stock Request" />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Medal from "./Medal.vue";
import Statistics from "./Statistics.vue";
import RequestTracker from "./RequestTracker.vue";
import BarChart from "./BarChart.vue";
import RadarChart from "./RadarChart.vue";
import Repository from "@/repositories/RepositoryFactory";

const DashboardRepository = Repository.get("dashboard");

export default {
  components: {
    BRow,
    BCol,

    Medal,
    Statistics,
    RequestTracker,
    BarChart,
    RadarChart,
  },
  data() {
    return {
      data: {
        congratulations: {
          totalItem: 0,
        },
        statisticsItems: [
          {
            icon: "BoxIcon",
            color: "primary",
            title: 0,
            subtitle: "In Stock",
          },
          {
            icon: "BoxIcon",
            color: "warning",
            title: 0,
            subtitle: "Low Stock",
          },
          {
            icon: "BoxIcon",
            color: "danger",
            title: 0,
            subtitle: "Out of Stock",
          },
        ],
        supportTracker: {
          title: "Request Tracker",
          totalRequests: 0,
          requested: 0,
          approved: 0,
          prepared: 0,
          supportTrackerRadialBar: {
            series: [0],
          },
        },
        sections: [],
        sectionsData: [],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      DashboardRepository.summary()
        .then((response) => {
          const data = response.data.data;
          this.data.congratulations.totalItem = data.totalOpenStockRequest;
          this.data.statisticsItems[0].title = data.totalInStockItem;
          this.data.statisticsItems[1].title = data.totalLowStockItem;
          this.data.statisticsItems[2].title = data.totalOutOfStockStockItem;
          this.data.supportTracker.totalRequests = data.totalStockRequest; 
          this.data.supportTracker.requested = data.totalRequestedStockRequest; 
          this.data.supportTracker.approved = data.totalApprovedStockRequest; 
          this.data.supportTracker.prepared = data.totalIssuedStockRequest; 
          this.data.supportTracker.supportTrackerRadialBar.series[0] = data.completeRequestPercentage;
          this.data.sections = [...data.sections];
          this.data.sectionsData = [...data.sectionsData];
        })
        .catch(() => {
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
